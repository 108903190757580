import React, { useMemo } from "react";

import SubscribeForm from "@/components/SubscribeForm";
import { Skeleton } from "@/components/ui";
import { useUserInfo } from "@/core/Auth/hooks";
import { Notification_Api_SendgridListSubscriptionTypeEnum } from "@/graphql-schema-types.generated";

import { useGetSubscriptionsQuery } from "./graphql/GetSubscriptions.generated";

import styles from "./styles.module.scss";

const Subscribe = () => {
  const auth = useUserInfo();
  const { data, loading } = useGetSubscriptionsQuery({
    skip: !auth?.id,
  });

  const isSubscribed = useMemo(
    () =>
      (data?.notification?.sendgrid_lists?.list?.subscriptions ?? []).some(
        ({ types }) =>
          types?.some((type) =>
            [
              Notification_Api_SendgridListSubscriptionTypeEnum.SendgridListSubscriptionTypeAllLandlords,
              Notification_Api_SendgridListSubscriptionTypeEnum.SendgridListSubscriptionTypeAllTenants,
            ].includes(type)
          )
      ),
    [data?.notification?.sendgrid_lists?.list?.subscriptions]
  );

  if (isSubscribed) {
    return null;
  }

  if (loading) {
    // Simple skeleton, if we need to add a complex skeleton the best way to do that is to use svg
    return <Skeleton className={styles.skeleton} />;
  }

  return (
    <section className={styles.root}>
      <div className={styles.bg} />
      <div className={styles.content}>
        <SubscribeForm
          formClassName={styles.form}
          titleClassName={styles.title}
        />
      </div>
    </section>
  );
};

export default Subscribe;
