import React, { useCallback, useMemo, useState } from "react";

import {
  composeFieldValidators,
  required,
} from "@dmitry.olyenyov/final-form-validations";
import cn from "classnames";
import { FORM_ERROR } from "final-form";
import { Field, Form } from "react-final-form";

import { Button } from "@/components/ui";
import { useUserInfo } from "@/core/Auth/hooks";
import ERRORS from "@/features/auth/utils/errors";
import { Notification_Api_SendgridListSubscriptionTypeEnum } from "@/graphql-schema-types.generated";
import { isValidEmail } from "@/lib/isValidEmail";

import { usePostSubscribeMutation } from "./graphql/PostSubscribeMutation.generated";
import { SubscribeFormProps, SubscribeFormValues } from "./types";

import styles from "./styles.module.scss";

export const SubscribeForm = ({
  titleClassName,
  formClassName,
}: SubscribeFormProps) => {
  const me = useUserInfo();
  const [showThanks, setShowThanks] = useState(false);
  const [subscribeMe] = usePostSubscribeMutation();

  const handleSubmit = useCallback(
    (values: SubscribeFormValues) =>
      subscribeMe({
        variables: {
          email: values.email,
          types: [values.subscribeType],
        },
        refetchQueries: ["PostMySubscriptionDocument"],
      }).then((res) => {
        if (
          res.data?.notification?.sendgrid_lists?.subscribe?.subscription.types?.includes(
            values.subscribeType
          )
        ) {
          setShowThanks(true);
        } else if ((res.errors?.length ?? 0) > 0) {
          return { [FORM_ERROR]: res.errors?.join(", ") };
        }

        return undefined;
      }),
    [subscribeMe]
  );

  const initialValues = useMemo(
    () => ({
      subscribeType:
        Notification_Api_SendgridListSubscriptionTypeEnum.SendgridListSubscriptionTypeAllLandlords,
      email: me?.contact?.email_primary ?? "",
    }),
    [me?.contact?.email_primary]
  );

  if (showThanks) {
    return (
      <div className={cn(styles.title, titleClassName)}>
        Thanks, you’re subscribed!
      </div>
    );
  }

  return (
    <Form<SubscribeFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, submitting, hasSubmitErrors }) => (
        <form onSubmit={handleSubmit}>
          <div className={cn(styles.title, titleClassName)}>
            Subscribe to our{" "}
            <span className={styles.controls}>
              <Field
                name="subscribeType"
                type="radio"
                value={
                  Notification_Api_SendgridListSubscriptionTypeEnum.SendgridListSubscriptionTypeAllLandlords
                }
              >
                {({ input }) => (
                  <label>
                    <input {...input} />
                    <span
                      className={cn(styles.control, {
                        [styles.controlSelected]: input.checked,
                      })}
                    >
                      landlord
                    </span>
                  </label>
                )}
              </Field>{" "}
              /{" "}
              <Field
                name="subscribeType"
                type="radio"
                value={
                  Notification_Api_SendgridListSubscriptionTypeEnum.SendgridListSubscriptionTypeAllTenants
                }
              >
                {({ input }) => (
                  <label>
                    <input {...input} />
                    <span
                      className={cn(
                        styles.control,
                        input.checked ? styles.controlSelected : undefined
                      )}
                    >
                      tenant
                    </span>
                  </label>
                )}
              </Field>
            </span>{" "}
            newsletter
          </div>
          <div className={cn(styles.formRow, formClassName)}>
            <div className={styles.controlInput}>
              <Field<string | undefined>
                name="email"
                placeholder="Enter your email"
                validate={composeFieldValidators(required(), (v) =>
                  isValidEmail(v as string)
                    ? undefined
                    : ERRORS.EMAIL_IS_NOT_CORRECT
                )}
              >
                {({ input, meta }) => (
                  <>
                    <input
                      {...input}
                      className={cn(styles.input, {
                        [styles.inputError]:
                          meta.touched && (meta.error || meta.submitError),
                      })}
                      placeholder="Enter your email"
                    />
                    {meta.touched && (meta.error || meta.submitError) && (
                      <div className={styles.inputErrorMessage}>
                        {meta.error || meta.submitError}
                      </div>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className={styles.controlButton}>
              <Button
                disabled={hasSubmitErrors}
                fullWidth
                loading={submitting}
                shape="rounded"
                size="lg"
                theme="primary"
                type="submit"
              >
                Subscribe
              </Button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default SubscribeForm;
