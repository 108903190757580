const ERRORS = {
  EMAIL_IS_REQUIRED: "E-mail is a required field",
  EMAIL_IS_NOT_CORRECT: "Invalid email",
  PASSWORD_IS_REQUIRED: "Password is a required field",
  PASSWORD_IS_NOT_CORRECT:
    "Password should be at least 8 characters including at least one uppercase letter, one lowercase letter and one digit",
  PASSWORD_IS_TOO_LONG: "Too long",
  PASSWORD_DOESNT_MATCH: "Passwords do not match",
  CODE_IS_REQUIRED: "Code is a required field",
  CODE_IS_TOO_SHORT: "Your verification code is too short",
  CODE_IS_WRONG: "Your code isn't correct",
  PHONE_IS_REQUIRED: "Phone is a required field",
  FIRSTNAME_IS_REQUIRED: "First name is a required field",
  SURNAME_IS_REQUIRED: "Surname is a required field",
};

export default ERRORS;
